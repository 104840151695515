import { registerMicroApps, start, setDefaultMountApp } from 'qiankun';
import React from 'react';
import ReactDOM from 'react-dom';
import { loadAuthValue } from '../config/oryCallback';

const config = [
    {
        name: 'idp-studio',
        entry: process.env.NODE == 'dev' ? '//localhost:8090' : '/child/idpStudio-idp/',
        container: '#root',
        activeRule: '/studio',
        enable: true
    },
    {
        name: 'idp-login',
        entry: process.env.NODE == 'dev' ? '//localhost:8091' : '/child/idpStudio-login/',
        container: '#root',
        activeRule: '/login',
        enable: true
    },
    {
        name: 'idp-team',
        entry: process.env.NODE == 'dev' ? '//localhost:8092' : '/child/idpStudio-team/',
        container: '#root',
        activeRule: '/team',
        enable: true
    },
    {
        name: 'idp-monitor',
        entry: process.env.NODE == 'dev' ? '//localhost:8093' : '/child/idpStudio-monitor/',
        container: '#root',
        activeRule: '/monitor',
        enable: true
    },
    {
        name: 'idp-shareplatform',
        entry: process.env.NODE == 'dev' ? '//localhost:8094' : '/child/idpStudio-sharePlatform/',
        container: '#root',
        activeRule: '/sharePlatform',
        enable: process.env.REACT_APP_VERSION === 'MODEL'
    },
    {
        name: 'idp-register',
        entry: process.env.NODE == 'dev' ? '//localhost:8095' : '/child/idpStudio-register/',
        container: '#root',
        activeRule: '/register',
        enable: true
    },
    {
        name: 'idp-marketplace',
        entry: process.env.NODE == 'dev' ? '//localhost:8096' : '/child/idpStudio-marketplace/',
        container: '#root',
        activeRule: '/marketplace',
        enable: true
    }
];

loadAuthValue().then(res => {
    registerMicroApps(config.filter(it => it.enable));
    // 启动 qiankun
    start({ prefetch: false });
    let path = '/studio'

    switch(process.env.REACT_APP_VERSION){
        case 'MODEL':
            path='/sharePlatform'
            break
        case 'MARKETPLACE':
            path='/marketplace'
            break
        default: 
            path = '/studio'
            break
    }
    // const path = process.env.REACT_APP_VERSION == 'MODEL' ? '/sharePlatform' : '/studio';
    setDefaultMountApp(path);
});

function render() {
    ReactDOM.render(<></>, document.querySelector('#root'));
}

render();
