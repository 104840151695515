import { analysisUrl } from '../studio/config/auth';
const { port } = require('../config/global');
import axios from 'axios';

async function loadAuthValue() {
    const search = new URLSearchParams(window.location.search);
    const code = search.get("code");
    const scope = search.get("scope");
    const state = search.get("state");
    return await oryCallback(code, scope, state);
}

async function oryCallback(code, scope, state) {
    let result = new Promise((resolve, reject) => { resolve({ href: undefined, sourceEntry: 'auth2' }) });
    if (code && scope && state) {
        axios.defaults.headers['Content-Type'] = 'application/json; charset=utf-8';
        if (code && scope && state) {
            result = await axios.get(`/0/api/v1/user/ory/callback?code=${code}&scope=${scope}&state=${state}&redirect_uri=${window.location.origin}/`);
            if (process.env.NODE == 'pro' && result.data?.data?.redirectTo) {
                window.location.href = `//${result.data.data.redirectTo}/lm/datacollect`
            } else {
                if (process.env.NODE == 'dev') {
                    window.location.href = `${window.location.origin}/lm/datacollect`;
                }
            }
            result = new Promise((resolve, reject) => { resolve({ href: true, sourceEntry: 'login' }) });
        }
    }
    return result
}

export {
    oryCallback,
    loadAuthValue,
};